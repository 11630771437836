import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Form } from '../../../components'
import { getAPI, postAPI } from '../../../utils';
import { useUsersChannel } from '../../Layout/WebSocketProvider';
import { UserMultiAutocompleteObjectInput } from '../Users/UserMultiAutocomplete';

const WarehouseForm = ({editDocId, onClose}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [ doc, setDoc ] = useState({default: false, noneteable: false, userIds:[]});
    const { reloadConfigs } = useUsersChannel();
    useEffect( () => {
        console.log('recargo WAREHOISE', editDocId)
    }, [editDocId, onClose])

    const resources = useSelector(state => state.resources);
    const options_recursos = resources['fabrica'].map( it => {return {label: it.nombre, value: it.nombre}})

    useEffect( () => {
        debugger
        // reads data to Edit instead of using datagrid row
        if (editDocId === 'new') return 
        getAPI('/settings/warehouses/' + editDocId)
        .then(data => {
            setDoc(data)
        })
        .catch(err => {
            debugger
            console.log(err.message)
            enqueueSnackbar(err.message, {variant: "error"})
        });   
      }, [editDocId, enqueueSnackbar])
      
    const handleSubmit = (data) => {
        debugger
        let id = editDocId;
        if (id === 'new') {
            id = "";
            delete data._id
        }
        if (data.userIds) {
            data.userIds = data.userIds.map(it => it._id)
        }
        postAPI('/settings/warehouses/' + id, data)
        .then(res => {
            console.log(res)
            enqueueSnackbar("Se guardo el cambio correctamente", {variant: 'info'});
            reloadConfigs();
            onClose(res)
        })
        .catch(err => {
            debugger
            console.log(err.message)
            enqueueSnackbar(err.message, {variant: "error"})
        });    
    }
  
    const fields = [
        {
            label: 'Planta',
            name: 'planta',
            options: options_recursos,
            sizes: {xs:4},
        },
        {
            label: 'Codigo',
            name: 'codigo',
            rules: {
                validate: {
                    exists: (v) => !!v || 'Es obligatorio',
                }
            },
            sizes: {xs:4},
        },
        {
          label: 'Descripción',
          name: 'descripcion',
          rules: {
              validate: {
                  exists: (v) => !!v || 'Es obligatorio',
              }
          },
          sizes: {xs:4},
        },
        {
            label: 'Es Default?',
            name: 'default',
            type: "boolean",
            sizes: {xs:4},
        },
        {
            label: 'Es Noneteable?',
            name: 'noneteable',
            type: "boolean",
            sizes: {xs:4},
        },
        {
            label: 'Usuarios',
            name: 'userIds',
            Component: UserMultiAutocompleteObjectInput,
            defaultValue: doc.userIds,
            sizes: {xs:4},
        }
    ]
  
    return ( 
        <Box>
            <Form 
                fields={fields}
                defaultValues={doc}
                onSubmit={handleSubmit}
                //onSubmitDiff={onSubmitDiff}
                actionsComp={ (onSubmit, isValid) => (
                    <Box sx={{textAlign: 'center', margin:'10px'}}>
                        <Button 
                            sx={{minWidth: '100px', marginRight: '10px'}}
                            color='secondary'
                            variant='outlined' 
                            onClick={onClose}>Cancelar</Button>
                        <Button 
                            sx={{minWidth: '100px'}}
                            variant="outlined" 
                            disabled={!isValid} 
                            onClick={onSubmit}>Guardar</Button>
                    </Box>
                    )                    
                }
                debug={false}
            />
        </Box>
    )
}
  
export default WarehouseForm;