import React, {useCallback} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {useSelector} from "react-redux";
import PersistUser from './PersistUser';
import {checkUserRoles} from '../../utils';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import {LoginPage, ResetPasswordPage, AccountsVerifyEmailPage} from './Login';
import Logout from './Logout';

import {NotAccesiblePage} from './NotAccesible';
import CircularProgressPage from './CircularProgressPage';
import AppContainer from './AppContainer';
import TestPage from "./TestPage";

const LoginPage1 = () => <LoginPage title='Ingreso a GestionABS'/>

const AppRouter = ({routes=[]}) => {
    const currentUser = useSelector(state => state.currentUser);
    const checkRoles = (roles=[]) => {
        // return true
        return roles && roles.length > 0 ? checkUserRoles(currentUser, roles) : true; 
    }
    return (
        <BrowserRouter basename={"/"}>
            <PersistUser devel={false}>
            <DndProvider backend={HTML5Backend}>

                <AppContainer>
                    <Routes>
            
                        <Route path="/test" element={ <TestPage />} />

                        <Route path="/login" element={ <LoginPage1/>} />    
                        <Route path="/:db/login" element={<LoginPage1/>} />

                        <Route path="/logout" element={ <Logout/>} />    
                        <Route path="/reset-password/:token" element={ <ResetPasswordPage/>} />    
                        <Route path="/verify-email/:token" element={ <AccountsVerifyEmailPage/>} />    

                        {
                            currentUser && 
                            currentUser.active &&
                                routes.map( ({path, index, element, roles}) => (  
                                            <Route 
                                                key={ path }
                                                path={ path }
                                                index={ index }
                                                element={ !!checkRoles(roles) ? element : <NotAccesiblePage/>}
                                            />
                                ) )
                        }
                        
                        {/* <Route
                            path="*"
                            element={
                                !currentUser || currentUser && !currentUser.active ? 
                                    <LoginPage1 /> : 
                                    <CircularProgressPage/>
                            }
                        /> */}

                    </Routes>
                </AppContainer>
                </DndProvider>
            </PersistUser>
        </BrowserRouter>
    );
}

export default AppRouter;