import React, {useState, useMemo, useEffect, useCallback} from 'react';
import { useTheme } from '@mui/material/styles';
import { useSelector } from "react-redux";
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';

import {PriceListAutocompleteObjectInput} from '../PriceList/PriceListAutocomplete';
import {PartAutocompleteObjectInput} from '../Part/PartAutocomplete';
import { HeaderBar, Form } from '../../components';
import {checkUserRoles, useUnits} from "../../utils";
import { options_moneda } from '../../appConfig';

import moment from 'moment';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalDialog({open, tipo, tratamiento, moneda, line={}, onCancel, onSubmit}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [proveeUnits, setProveeUnits] = useState([]);
    const [plist, setPlist] = useState({});
    const [vidrio, setVidrio] = useState({});
    const {unitOptions} = useUnits()
    const currentUser = useSelector(state => state.currentUser);
    const terminacionColores = useSelector( state => state.terminacionColores || [] );
    const disabled = !checkUserRoles(currentUser, ['local-admin', 'compras-edit'])
    const title = line._id === 'new' ? "Nueva Linea"  + (tratamiento ? " DE TRATAMIENTO" : "") : "Edición de Linea #" + line.linea;

    debugger
    let base_units = [];
    const units = useSelector(state => state.units);
    units.map( it => {
        if (base_units.indexOf(it.baseUnit) == -1) {
            base_units.push(it.baseUnit);
        }
    })
    base_units = base_units.map( it => ({label:it, value:it} ))

    let line1 = useMemo( () => {
        let pl = {};
        if (tipo === 'MATERIALES') {
          pl = {
            pl: {
              articulo: line.articulo,
              provee: line.provee,
            }
          }
          const unidades = unitOptions(line.udm_depo);
          setProveeUnits(unidades || [])
        }
        if (tipo === 'VIDRIOS' && line.vidrio) {
          pl = {
            pl1: {
              articulo: line.vidrio.c1Id,
              provee: line.provee
            },
            plm: {
              articulo: line.vidrio.cmId,
              provee: line.provee
            },
            pl2: {
              articulo: line.vidrio.c2Id,
              provee: line.provee
            },
          }
        }
        return line._id !== 'new' ? {
          ...line,
          ...pl, 
        } : {moneda, ...line};
  
    }, [tipo, line])
    

    const watchChanges = (value, name, type, getValues, setValue) => {
      debugger
      if ( type==='change') {
          if (name === 'articulo') {
            debugger
            const deposito_udm = getValues('articulo.deposito_udm');
            setValue('udm', deposito_udm)
          }
          if (name === 'pl') {
            const deposito_udm = getValues('pl.articulo.deposito_udm');
            if (!deposito_udm) {
              setValue('udm', '')
            } else {
              const provee_udm = getValues('pl.articulo.provee_udm')
              setValue('udm', provee_udm ? provee_udm : deposito_udm)   
            }

            const unidades = unitOptions(deposito_udm)
            setProveeUnits(unidades || [])
            
            setValue('precio', getValues('pl.precio'));
            setValue('moneda', getValues('pl.moneda'));
            setValue('articulo', getValues('pl.articulo'));
            setValue('provee', getValues('pl.provee'));

            setPlist({
              vigencia_desde: getValues('pl.vigencia_desde'), 
              vigencia_hasta: getValues('pl.vigencia_hasta'),
            })
          }
          if (tipo === 'VIDRIOS') {
            if (name === 'vidrio.cantidad') {
              setValue('pedido', getValues('vidrio.cantidad'))
            }
            if (name === 'pl1') {
              setValue('vidrio.precios.0', getValues('pl1.precio'));
              setValue('vidrio.monedas.0', getValues('pl1.moneda'));
              setValue("vidrio.c1_desc", getValues('pl1.articulo.descripcion'));
              setValue('vidrio.c1', getValues('pl1.articulo.codigo'))
              setValue('vidrio.c1Id', getValues('pl1.articulo._id'))
            }
            if (name === 'plm') {
              setValue('vidrio.precios.1', getValues('plm.precio'));
              setValue('vidrio.monedas.1', getValues('plm.moneda'));
              setValue("vidrio.cm_desc", getValues('plm.articulo.descripcion'));
              setValue('vidrio.cm', getValues('plm.articulo.codigo'))
              setValue('vidrio.cmId', getValues('plm.articulo._id'))
            }
            if (name === 'pl2') {
              setValue('vidrio.precios.2', getValues('pl2.precio'));
              setValue('vidrio.monedas.2', getValues('pl2.moneda'));
              setValue("vidrio.c2_desc", getValues('pl2.articulo.descripcion'));
              setValue('vidrio.c2', getValues('pl2.articulo.codigo'))
              setValue('vidrio.c2Id', getValues('pl2.articulo._id'))
            }
            const color = [getValues("vidrio.c1_desc"), getValues("vidrio.cm_desc"), getValues("vidrio.c2_desc")];
            setValue('articulo_color', color.join(' | '));

            setVidrio( getValues('vidrio') );
          }
          if (tipo === 'VARIOS') {
            setProveeUnits(base_units || [])
            
          }
      } 
    }


    const materialesDialog = [
      {
        label: 'Articulo a Tratamiento',
        name: "articulo",
        Component: PartAutocompleteObjectInput,
        hidden: !tratamiento,
        sizes: {xs:12}
      },
      {
        label: 'Articulo',
        name: 'pl',
        Component: ({...other}) => <PriceListAutocompleteObjectInput proveeId={line.provee && line.provee._id} tipo="1" {...other}/>,
        rules: {
          validate: {
              exists: (v) => !!v || 'Es obligatorio',
          }
        },
        hidden: !!tratamiento,
        sizes: {xs:6},
      },
      {
        label: 'Color',
        name: 'articulo_color',
        options: terminacionColores.map(it => ({label:it, value:it})),
        hidden: !!tratamiento,
        sizes: {xs:6},
      },
      {
        label: 'Pedido',
        name: 'pedido',
        rules: {
          validate: {
              exists: (v) => !!v || 'Es obligatorio',
          }
        },  
        sizes: {xs:3},
      },
      {
        label: 'UDM1',
        name: 'udm',
        options: proveeUnits,
        rules: {
          validate: {
              exists: (v) => !!v || 'Es obligatorio',
          }
        },
        disabled: !!tratamiento,
        sizes: {xs:3},
      },
      {
        label: 'Precio',
        name: 'precio',
        disabled: true,
        hidden: !!tratamiento,
        sizes: {xs:3},
      },
      {
        label: 'Moneda',
        name: 'moneda',
        disabled: true,
        hidden: !!tratamiento,
        sizes: {xs:3},
      },
    ]

    const variosDialog = [
      { 
        label: 'Articulo',
        name: 'descripcion',
        rules: {
          validate: {
              exists: (v) => !!v || 'Es obligatorio',
          }
        },  
        sizes: {xs:12},
      },
      {
        label: 'Pedido',
        name: 'pedido',
        rules: {
          validate: {
              exists: (v) => !!v || 'Es obligatorio',
          }
        },  
        sizes: {xs:3},
      },
      {
        label: 'UDM',
        name: 'udm',
        options: base_units,
        rules: {
          validate: {
              exists: (v) => !!v || 'Es obligatorio',
          }
        },  
        sizes: {xs:3},
      },
      {
        label: 'Precio',
        name: 'precio',
        sizes: {xs:3},
      },
      {
        label: 'Moneda',
        name: 'moneda',
        options: options_moneda,
        sizes: {xs:3},
      },
    ]

    const vidriosDialog = [
      {
        label: 'Codigo',
        name: 'vidrio.codigo',
        rules: {
          validate: {
              exists: (v) => !!v || 'Es obligatorio',
          }
        },  
        sizes: {xs:2},
      },
      {
        label: 'Cantidad',
        name: 'vidrio.cantidad',
        rules: {
          validate: {
              exists: (v) => !!v || 'Es obligatorio',
          }
        },  
        sizes: {xs:2},
      },
      {
        label: 'Ancho (mm)',
        name: 'vidrio.ancho',
        rules: {
          validate: {
              exists: (v) => !!v || 'Es obligatorio',
          }
        },  
        sizes: {xs:2},
      },
      {
        label: 'Alto (mm)',
        name: 'vidrio.alto',
        rules: {
          validate: {
              exists: (v) => !!v || 'Es obligatorio',
          }
        },  
        sizes: {xs:2},
      },
      {
        label: 'Observacion',
        name: 'vidrio.obs',
        sizes: {xs:4},
      },
      {
        label: 'Cara 1',
        name: 'pl1',
        Component: ({...other}) => <PriceListAutocompleteObjectInput proveeId={line.provee && line.provee._id} tipo="1" {...other}/>,
        rules: {
          validate: {
              exists: (v) => !!v || 'Es obligatorio',
          }
        },  
        sizes: {xs:4},
      },
      {
        label: 'Camara',
        name: 'plm',
        Component: ({...other}) => <PriceListAutocompleteObjectInput proveeId={line.provee && line.provee._id} tipo="1" {...other}/>,
        sizes: {xs:4},
      },
      {
        label: 'Cara 2',
        name: 'pl2',
        Component: ({...other}) => <PriceListAutocompleteObjectInput proveeId={line.provee && line.provee._id} tipo="1" {...other}/>,
        sizes: {xs:4},
      },
      {
        label: 'Precio',
        name: 'vidrio.precios.0',
        disabled: true,
        sizes: {xs:4},
      },
      {
        label: 'Precio',
        name: 'vidrio.precios.1',
        disabled: true,
        sizes: {xs:4},
      },
      {
        label: 'Precio',
        name: 'vidrio.precios.2',
        disabled: true,
        sizes: {xs:4},
      },
    ]

    let dialogFields = [];
    if (tipo === 'MATERIALES') {
      dialogFields = materialesDialog
    }
    if (tipo === 'VARIOS') {
      dialogFields = variosDialog
    }
    if (tipo === 'VIDRIOS') {
      dialogFields = vidriosDialog
    }

    return (
      <Dialog 
        fullScreen={fullScreen}
        maxWidth="lg"
        open={open} 
        onClose={onCancel}
        TransitionComponent={Transition}
      >
        <HeaderBar 
          title={title}
          actions={[
            <IconButton
                edge="start"
                color="inherit"
                onClick={onCancel}
                aria-label="close"
            >
                <CloseIcon />
            </IconButton>
          ]}
        />

        <DialogContent>
          <Form
            defaultValues={line1}
            fields={dialogFields}
            watchChanges={watchChanges}
            onSubmit={onSubmit}
            actionsComp={ (onSubmit, isValid) => (
              <Box sx={{textAlign: 'center', margin:'10px'}}>
                  <Button 
                      sx={{minWidth: '100px', marginRight: '10px'}}
                      color='secondary'
                      variant='outlined' 
                      onClick={onCancel}>Cancelar</Button>
                  <Button 
                      sx={{minWidth: '100px'}}
                      variant="outlined" 
                      disabled={!isValid} 
                      onClick={onSubmit}>Guardar</Button>
              </Box>
              )                    
            }
            debug={false}
          />
          { tipo === 'MATERIALES' &&
            <Box>
              Precio: {plist.precio} {plist.moneda}&nbsp;
              Vigencia: {moment(plist.vigencia_desde).utc().format("YYYY-MM-DD")} / {moment(plist.vigencia_hasta).utc().format("YYYY-MM-DD")}&nbsp;           
            </Box>
          }
          { tipo === 'VIDRIOS' &&
            <Box>
              Precios Composición: {vidrio && vidrio.precios && vidrio.precios.join(' | ')} &nbsp;
              Vigencia: {moment(plist.vigencia_desde).utc().format("YYYY-MM-DD")} / {moment(plist.vigencia_hasta).utc().format("YYYY-MM-DD")}             
            </Box>
          }

        </DialogContent>
      </Dialog>
  )
}
