import React, {forwardRef, useState, useEffect, useCallback, useImperativeHandle} from 'react'; 

import { useSearchParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {PageBase, PageTitle, SearchBox, ExportButton} from '../../components'; 
import {getAPI, checkUserRoles, useTabs, debounce} from '../../utils' ;
import PostSaleList from './PostSaleList';
import ActivityOpenClose  from '../Activities/ActivityOpenClose';
import {UserSearchBox} from '../Settings/Users/UserSearchBox';

import {options_postventa_status, EXCEL_LIMIT} from "../../appConfig.js"

import moment from 'moment';

const IndexPage = forwardRef( (_, ref) => {
    debugger
    let [searchParams] = useSearchParams();
    const { enqueueSnackbar }       = useSnackbar();
    const [filter, setFilter]       = useState(searchParams.get("filter") || "");
    const [obra, setObra]           = useState(searchParams.get("obra") || "");
    const [direccion, setDireccion] = useState(searchParams.get("direccion") || "");
    const [status, setStatus]       = useState(searchParams.get("status") || "-1");
    const [tipo, setTipo]           = useState(searchParams.get("tipo") || "-1");
    const [presu, setPresu]           = useState(searchParams.get("presu") || "-1");
    const [distribuidor, setDistribuidores]= useState(searchParams.get("distribuidor") || "-1");
    const [ownerId, setOwnerId]     = useState("-1");

    const [reload, setReload] = useState(false);
    const {appendTab} = useTabs({
        onReload: (reload) => {
            setReload(reload)
        }
    });

    const [num, setNum] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const limit = 100;

    const currentUser = useSelector(state => state.currentUser); 
    const esLocalAdmin = checkUserRoles(currentUser, ['local-admin', 'gerencia', 'posventa-edit'])

    const optionsStatus = [{label: 'TODOS', value:'-1'}, {label: 'PARA APROBAR', value:'-2'}].concat(options_postventa_status);
    const canOpts = useSelector( state => state.distributors)
    const optionsDistrib = canOpts ? ([{label: 'TODOS', value:'-1'}].concat(canOpts.map(it => ({label:it.nombre, value:it._id}) )  )) : [];
    
    const optionsAll = useSelector(state => state.options) || {};
    const options_postventa_tipo = [{label:'TODOS', value:"-1"}].concat(optionsAll['postsaleType'] || []);

    const optionsPresupuesto = [{label: 'TODOS', value:"-1"}, {label:'SI', 'value':'SI'}, {label:'NO', 'value':'NO'}];

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    // setRows(doc)
    useImperativeHandle(ref, () => ({
      getAlert() {
          alert("getAlert from Child");
      }
    }));  

    const delayedFunc = useCallback( 
        debounce( ({...other}) => {
            setLoading(true)
            getAPI('/postsale/postsales', {...other, sort: 'updated_at:-1'})
            .then(data => {
                setLoading(false);
                debugger
                if (data) {
                    data.list.forEach( it => it.id = it._id);
                    setNum(data.num)
                    setRows(data.list)
                }
              })
            .catch(err => {
                console.error(err)
                enqueueSnackbar(err.message, {variant: 'error'})
            });    
        }, 500)
    , [page, filter, obra, direccion, status, tipo, presu, distribuidor, ownerId, reload])

    useEffect( () => {
        setReload(false);
        delayedFunc({page, filter, direccion, obra, status, tipo, presu, distribuidor, ownerId, reload})
    }, [delayedFunc, page, filter, direccion, obra, status, tipo, presu, distribuidor, ownerId, reload])

    const handleSearch = fld => (ev) => {
        debugger
        if (fld === 'filter') setFilter(ev.target.value)
        if (fld === 'obra') setObra(ev.target.value)
        if (fld === 'direccion') setDireccion(ev.target.value)
        if (fld === 'status') setStatus(ev.target.value)
        if (fld === 'tipo') setTipo(ev.target.value)
        if (fld === 'presu') setPresu(ev.target.value)
        if (fld === 'distribuidor') setDistribuidores(ev.target.value)
        if (fld === 'ownerId') setOwnerId(ev.target.value)
    }

    const handleClick= tipo => item => (ev) => {
        ev.preventDefault();
        if (item.id) appendTab(tipo)(item)
    }

    const configReport = useCallback( async () => {
        const reportConfig = {
            title: `Posventas`,
            subTitle: "Creado por " + currentUser.username + " el " + moment().local().toISOString(),
            cols: [
                    {title: 'PV', field: 'pv'},
                    {title: 'Nombre', field: 'nombre'},
                    {title: 'Contacto', field: '_', transform: (v, doc) => {
                        if (doc.contacto) {
                            return doc.contacto.fullname;
                        }
                    }},
                    {title: 'Obra', field: '_', transform: (v, doc) => {
                        if (doc.obra) {
                            return doc.carpeta + " " + doc.obra.nombre;
                        }
                    }},
                    {title: 'Status', field: 'status'},
                    {title: 'Con Presupuesto', field: '_', transform: (v, doc) => {
                        const hay = doc.archivo_presupuesto ? " / " + doc.archivo_presupuesto.length : ""  
                        return doc.con_presupuesto + hay
                    }},
                    {title: 'En Garantia', field: 'en_garantia'},
                    {title: 'Dias Enviada', field: '_', transform: (v, doc) => {
                        const fecha = doc.fecha_enviada || doc.fecha_aceptada || doc.fecha_aprobada;
                        return fecha ? moment().diff(moment(fecha), 'days') : "-"
                    }},
                    // {title: 'Distribuidor', field: '_', transform: (v, doc) => doc.distributorId && doc.distributorId.codigo},
                    {title: 'Tipo', field: 'tipo'},
                    {title: 'Bario', field: 'bario'},
                    {title: 'Lote', field: 'lote'},
                    {title: 'Direccion', field: 'direccion'},
                    {title: 'Partido', field: 'partido'},
                    {title: 'Email', field: 'email'},
                    {title: 'Telefono', field: 'telefono'},
                    // {title: 'Presupuesto', field: 'presupuesto'},
                    {title: 'Responsable', field: '_', transform: (v, doc) => doc.ownerId && doc.ownerId.username},
                    // {title: 'Barrio', field: 'barrio'},
                    // {title: 'Localidad', field: 'localidad'},
                    {title: 'Creado', field: 'created_at', transform: (v, doc) => moment(v).utc().format('YYYY-MM-DD')},
                    // {title: 'id', field: '_id'}
            ],
            styles: {}
        }
    
        const rows = await getAPI('/postsale/postsales', {filter, obra, direccion, status, tipo, presu, distribuidor, ownerId, skip: 0, limit:3*EXCEL_LIMIT, sort: 'updated_at:-1'})

        reportConfig['rows'] = rows.list;
    
        return reportConfig;
    }, [filter, obra, direccion, status, tipo, presu, distribuidor, ownerId, currentUser]);

    const columnVisibilityModel = {
        actions: esLocalAdmin
    }

    return (
        <PageBase
            paperProps={{width:'100%', height: 'calc(100% - 98px)'}} 

            title={
                <div>
                    <PageTitle>PostVentas ({num})</PageTitle> 
                    <SearchBox label="PV/Nombre/Presu" value={filter} onChange={handleSearch('filter')}/>
                    <SearchBox label="Obra" value={obra} onChange={handleSearch('obra')}/>
                    <SearchBox label="Barrio/Direccion/Localidad" value={direccion} onChange={handleSearch('direccion')}/>
                    <SearchBox label="Presupuesto" select options={optionsPresupuesto} value={presu} onChange={handleSearch('presu')}/>
                    <SearchBox label="Status" select multiple options={optionsStatus} value={status} onChange={handleSearch('status')}/>
                    <SearchBox label="Tipo" select options={options_postventa_tipo} value={tipo} onChange={handleSearch('tipo')}/>
                    <SearchBox label="Distribuidores" select options={optionsDistrib} value={distribuidor} onChange={handleSearch('distribuidor')}/>
                    <UserSearchBox label="Usuarios" posventa value={ownerId} onChange={handleSearch('ownerId')}/>

                </div>
            }
            actions={
                <Box sx={{display:'flex'}}>
                    {esLocalAdmin && <Button sx={{m:1}} variant='outlined' size='small' onClick={handleClick('PVTA')({id: true, _id: 'new'})}>Nueva</Button>}
                    <ExportButton configReport={configReport}>EXCEL</ExportButton>
                    <ActivityOpenClose app='posventa' withLabel/>
                </Box>
            }   
       >           
            <PostSaleList
                loading={loading} 
                docs={rows} 
                num={num}
                limit={limit}

                columnVisibilityModel={columnVisibilityModel}

                onClick={handleClick}
                onPageChange={(newPage) => setPage(newPage)}
            />    

        </PageBase>
    )
})

export default IndexPage;