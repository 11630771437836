import React from 'react';

import {Dashboard, OrgIndex, OrgEdit, 
        ContactIndex,
        ComprasDashboard, POrderIndex, POrderDetailIndex, PartIndex, WorkOrderScheduleIndex, 
        StockIndex, StockMoveIndex, StockMaterialsIndex, StockPlanningIndex,
        MyProfile, SettingsIndex, ProjectIndex, ProjectPlanningIndex, CRMDashboard} from './ui'
import ContactEditModal from './ui/Contact/ContactEditModal';
import {TabsProvider} from './ui/Layout/TabsProvider';

//import { FaSignOutAlt } from 'react-icons/fa';

import InboxIcon from "@mui/icons-material/Inbox";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
//import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ShopIcon from '@mui/icons-material/Shop';
import TempleBuddhistIcon from '@mui/icons-material/TempleBuddhist';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import FactoryIcon from '@mui/icons-material/Factory';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
// import ContactIndex from  './ui/Contact/ContactIndex';
import OpportunityIndex from  './ui/Opportunity/OpportunityIndex';
import PostSaleIndex from  './ui/PostVenta/PostSaleIndex';
import WorkOrderIndex from  './ui/WorkOrder/WorkOrderIndex';
import TaskIndex from  './ui/Task/TaskIndex';

// export const SERVER_ATTACHMENT_PATH = "http://localhost:3000/files";
// OBS es clave que en devel apunte al server directo sin pasar por el proxy
export const SERVER_ATTACHMENT_PATH = process.env.NODE_ENV === 'production' ? "/files" : "http://localhost:5013/files";

//OBS para chequear desde servidor 'npx wscat -c ws://example.com/ws'
// const WS = process.env.REACT_APP_WEBSOCKET_PORT;
// export const WS_URL = process.env.NODE_ENV === 'production' ? 'ws://127.0.0.1:'+WS+'/' : 'ws://127.0.0.1:'+WS+'/';
// export const WS_URL = 'ws://127.0.0.1:8089/';
export const WS_URL = process.env.NODE_ENV === 'production' ? 'wss://gestionabs.com/ws' : 'ws://localhost:8090/ws';

// export const WS_URL = 'ws://127.0.0.1/ws';
// export const WS_URL = 'ws://127.0.0.1:3001/ws/';
// export const WS_URL = 'ws://127.0.0.1:3001/ws/'; 

const defaultRoles = ['local-admin', 'gerencia'];

const modTabs = {
    crm:             [
        {
            label: 'CRM',
            component: <CRMDashboard />,
            canClose: false,
            default: false,
            roles: [...defaultRoles, 'crm', 'crm-edit']
        },
        {
            label: 'CONTACTOS',
            component: <ContactIndex tipo='CONT' provee="0" />,
            canClose: false,
            default: false,
            roles: [...defaultRoles, 'crm', 'crm-edit']
        },
        {
            label: 'OPORTUNIDADES',
            component: <OpportunityIndex tipo='OPPO'/>,
            canClose: false,
            default: true,
            roles: [...defaultRoles, 'crm', 'crm-edit']
        },
        {
            label: 'OBRAS',
            component: <ProjectIndex tipo='OBRA' />,
            canClose: false,
            default: false,
            roles: [...defaultRoles, 'obra', 'obra-edit']
        },
        // {
        //     label: 'REQUERIMIENTOS',
        //     component: <ProjectPlanningIndex tipo='WORK-P'/>,
        //     canClose: false,
        //     default: false
        // },
    ],
    compras: [
        {
            label: 'COMPRAS',
            component: <ComprasDashboard />,
            canClose: false,
            default: true,
            roles: [...defaultRoles, 'compras', 'compras-edit']
        },
        {
            label: 'PROVEE',
            component: <ContactIndex provee="1" />,
            canClose: false,
            default: false,
            roles: [...defaultRoles, 'compras', 'compras-edit']
        },
        {
            label: 'PEDIDOS',
            component: <POrderIndex tipo='NP'/>,
            canClose: false,
            default: true,
            roles: [...defaultRoles, 'compras', 'compras-edit']
        },
        {
            label: 'DETALLE',
            component: <POrderDetailIndex tipo='NP'/>,
            canClose: false,
            roles: [...defaultRoles, 'compras', 'compras-edit']
        },
        {
            label: 'RECEPCIONES',
            component: <POrderIndex tipo='REC' />,
            canClose: false,
            roles: [...defaultRoles, 'compras', 'compras-edit']
        },
    ],
    produccion: [
        {
            label: 'OBRAS',
            component: <ProjectIndex tipo='OBRA' />,
            canClose: false,
            default: false,
            roles: [...defaultRoles, 'obra', 'obra-edit']
        },
        {
            label: 'PLANNING',
            component: <ProjectPlanningIndex tipo='WORK-P'/>,
            canClose: false,
            default: false,
            roles: [...defaultRoles, 'inventarios', 'inventarios-edit']
        },
        {
            label: 'FABRICA',
            component: <WorkOrderIndex tipo='WORK-F' />,
            canClose: false,
            default: false,
            roles: [...defaultRoles, 'fabrica', 'fabrica-edit']
        },
        {
            label: 'PLAN FAB',
            component: <WorkOrderScheduleIndex tipo='WORK-F'/>,
            canClose: false,
            default: true,
            roles: [...defaultRoles, 'fabrica', 'fabrica-edit']
        },
        {
            label: 'SERVICIOS',
            component: <WorkOrderIndex tipo='WORK-S' />,
            canClose: false,
            default: false,
            roles: [...defaultRoles, 'servicio', 'servicio-edit']
        },
        {
            label: 'PLAN SER',
            component: <WorkOrderScheduleIndex tipo='WORK-S'/>,
            canClose: false,
            default: true,
            roles: [...defaultRoles, 'servicio', 'servicio-edit']
        },
        {
            label: 'TAREAS',
            component: <TaskIndex />,
            canClose: false,
            default: false,
            roles: [...defaultRoles, 'fabrica', 'fabrica-edit', 'servicio', 'servicio-edit']
        },
    ],
    inventarios: [
        {
            label: "ARTICULOS",
            component: <PartIndex />,
            canClose: false,
            default: false,
            roles: [...defaultRoles, 'inventarios', 'inventarios-edit']
        },
        {
            label: "INVENTARIOS",
            component: <StockIndex />,
            canClose: false,
            default: false,
            roles: [...defaultRoles, 'inventarios', 'inventarios-edit']
        },
        {
            label: "MATERIALES",
            component: <StockMaterialsIndex />,
            canClose: false,
            default: true,
            roles: [...defaultRoles, 'inventarios', 'inventarios-edit']
        },
        {
            label: "PLANIFICACIÓN",
            component: <StockPlanningIndex />,
            canClose: false,
            default: false,
            roles: [...defaultRoles, 'inventarios', 'inventarios-edit']
        },
        {
            label: "MOVIMIENTOS",
            component: <StockMoveIndex />,
            canClose: false,
            roles: [...defaultRoles, 'inventarios', 'inventarios-edit']
        }
    ],
    postventa: [
        {
            label: 'CONTACTOS',
            component: <ContactIndex tipo='CONT' provee="0" />,
            canClose: false,
            default: false,
            roles: [...defaultRoles, 'posventa', 'posventa-edit']
        },
        {
            label: "POSVENTA",
            component: <PostSaleIndex tipo='PVTA' />,
            canClose: false,
            default: true,
            roles: [...defaultRoles, 'posventa', 'posventa-edit']
        },
    
    ]
}

export const routes = () => ([ 

    { path: '/',                      element: <Dashboard/> },
    { path: '/dashboard',             element: <Dashboard/> },
    // { path: '/myprofile',             element: <MyProfile/> },

    { path: '/crm',                   element: <TabsProvider key="crm"          defaultTabs={modTabs['crm']}/>,       roles: [...defaultRoles, 'crm', 'crm-edit']},
    { path: '/compras',               element: <TabsProvider key="compras"      defaultTabs={modTabs['compras']}/>,   roles: [...defaultRoles,'compras','compras-edit']},
    { path: '/work',                  element: <TabsProvider key="produccion"   defaultTabs={modTabs['produccion']}/>,   roles: [...defaultRoles,'fabrica','fabrica-edit','servicio','servicio-edit']},
    { path: '/stock',                 element: <TabsProvider key="inventarios"  defaultTabs={modTabs['inventarios']}/>, roles: [...defaultRoles,'inventarios','inventarios-edit']  },
    { path: '/postventa',             element: <TabsProvider key="postventa"    defaultTabs={modTabs['postventa']}/>, roles: [...defaultRoles,'posventa','posventa-edit'] },

    { path: '/settings',              index: true, element: <SettingsIndex/>, roles: ['local-admin','administracion'] },

    // { path: '/admin',                 index: true, element: <SettingsIndex/>, roles: ['admin'] },
    // { path: '/admin/orgs',            index: true, element: <OrgIndex/>, roles: ['admin'] },
    // { path: '/admin/orgs/:id',        element: <OrgEdit/>, roles: ['admin'] },

])

export const menuConfig = () => ([
    { button: "dashboard", icon: <DashboardIcon sx={{color:'#eee'}}/>,
        link: '/dashboard', 
        //menu: [
        //    { text: 'Contactos', icon: <AssessmentIcon/> , link: '/crm/contacts',  roles: [] },
        //    { text: 'Test', icon: <AccountBoxIcon/> , link: '/test',  roles: [] },
        //]
    },
    { button: "CRM", icon: <AssignmentIndIcon sx={{color:'#eee'}}/>, roles: [...defaultRoles, 'crm', 'crm-edit'],
        link: '/crm', 
    },
    { button: "COMPRAS", icon: <ShopIcon sx={{color:'#eee'}}/>, roles: [...defaultRoles, 'compras','compras-edit'],
        link: '/compras', 
    },
    { button: "PRODUCCION", icon: <FactoryIcon sx={{color:'#eee'}}/>, roles: [...defaultRoles, 'fabrica', 'fabrica-edit', 'servicio', 'servicio-edit'],
        link: '/work', 
    },
    { button: "INVENTARIOS", icon: <TempleBuddhistIcon sx={{color:'#eee'}}/>, roles: [...defaultRoles, 'inventarios', 'inventarios-edit'],
        link: '/stock', 
    },
    { button: "POSTVENTA", icon: <SupportAgentIcon sx={{color:'#eee'}}/>, roles: [...defaultRoles, 'posventa', 'posventa-edit'],
        link: '/postventa', 
    },
])

export const red = "#e00", green = '#0eb90e', yellow='yellow', blue="#00e";

export const roles = () => ([
    'admin', 'local-admin', 'gerencia', 'administracion',
    'crm', 'crm-edit', 'crm-presupuesto-ver', 'crm-prespuesto-aprobar', 'crm-medicion', 'crm-contacto',
    'obra', 'obra-edit', 'obra-tareas',
    'compras', 'compras-edit',
    'inventarios', 'inventarios-edit',
    'fabrica', 'fabrica-edit',
    'servicio', 'servicio-edit',
    'posventa', 'posventa-edit',
    'esVendedor', 'esPosventa'
])

export const options_opos_status = [
    "INGRESADA", "A PRESUPUESTAR", "EN TRATATIVA", 'GANADA', 'PERDIDA', 'CERRADA'
]

export const options_project_status = [
    {label: "SIN PLANILLA", value: "SP"},
    {label: "FABRICACION", value: "F"},
    {label: "COLOCACION", value: "C"},
    {label: "C90", value: "C90"},
    {label: "TCP", value: "TCP"},
    {label: "TERMINADA", value: "T"},
    {label: "COBRADA", value: "CO"},
    {label: "INCOBRABLE", value: "INCO"},
]
export const options_postventa_status = [
  {label: "INGRESADA", value: "INGRESADA"},
  {label: "A PRESUPUESTAR", value: "A PRESUPUESTAR"},
  {label: "ENVIADA", value: "ENVIADA"},
  {label: "ACEPTADA", value: "ACEPTADA"},
  {label: "NO ACEPTADA", value: "NO ACEPTADA"},
  {label: "APROBADA", value: "APROBADA"},
  {label: "CERRADA", value: "CERRADA"},
  {label: "SIN COBRAR", value: "SIN COBRAR"},
  {label: "DESAPROBADA", value: "DESAPROBADA"},
  {label: "ANULADA", value: "ANULADA"},
]
export const options_orders_fabrica_status = [
    "SIN PROCESAR", "PROCESADO", "EN FABRICACION", "FABRICADO"
]

export const options_orders_servicios_status = [
    "SIN PROGRAMAR", "PROGRAMADO", "CONFIRMADO", "EN PROCESO", "REALIZADO", "ANULADO"
]

export const options_porder_status = [
    'PARA PEDIR', 'APROBADO', 'DESAPROBADO', 'PEDIDO', 'CONFIRMADO', 'RECIBIDO','RECIBIDO CON PENDIENTE','DEVUELTO','ANULADO'
].map(it => ({label:it, value:it}))

export const options_task_status = [
    "PENDIENTE", "REALIZADO", "NO REALIZADO"
]

export const options_exito = [25, 50, 75, 100].map(it => ({label:it, value:it}));

export const options_moneda = ["USD", "ARG", "EUR"].map(it => ({label:it, value:it}))

export const options_dto_profesional = [
    'SIN', "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"
].map(it => ({label:it, value:it}))

export const options_porder_tipo = [
    'MATERIALES', 'VIDRIOS', 'VARIOS'
].map(it => ({label:it, value:it}))

export const options_lugarentrega = [
    "WELTTECHNIK", "EN OBRA", "OTROS"
].map(it => ({label:it, value:it}))

export const options_sino = [
    { value: "SI", label: "SI" },
    { value: "NO", label: "NO" },
]

export const options_tipo_mov = [
    {label: 'TODOS',   value: 'TODOS'},
    {label: 'TRAN',    value: 'TRAN,TRAN-LINK'},
    {label: 'STK-MFG', value: 'STK-MFG'},
    // {label: 'STK-RET', value: 'STK-RET'},
    {label: 'STK-VEN', value: 'STK-VEN'},
    {label: 'VEN-STK', value: 'VEN-STK'},
    {label: 'VEN-MFG', value: 'VEN-MFG'},
    {label: 'MFG-STK', value: 'MFG-STK'},
    {label: 'AJS',     value: 'AJS'},
    {label: 'AJS-COL', value: 'AJS-COL'},
    {label: 'AJS-COL2',value: 'AJS-COL2'},
    {label: 'BAJA',    value: 'BAJA'},
]

export const EXCEL_LIMIT = 5000;