import React, {useState, useEffect} from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";
import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { HeaderBar, PrintPreview } from '../../components';

import {getAPI, postAPI, useTabs, activityLog} from '../../utils';
import {checkUserRoles} from '../../utils/checkUserRoles';
import ProjectShow from './ProjectShow';
import ProjectEdit from './ProjectEdit';
import ProjectPrint from './ProjectPrint';
import moment from 'moment'
import { createTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ActivityOpenClose  from '../Activities/ActivityOpenClose';

const defaultTheme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#0971f1',
      darker: '#053e85',
    },
    red: {
      main: '#f20b56ed',
      contrastText: '#fff',
    },
    green: {
      main: '#8bc34a',
      contrastText: '#fff',
    },
    yellow: {
      main: '#ffeb3b',
      contrastText: '#333',
    }
  },
});


const ProjectShowEdit = ({docId, onReload, ...other}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [edit, setEdit] = useState(false);
    const [reload, setReload] = useState(false);
    const [loading, setLoading] = useState(false);
    const [doc, setDoc] = useState({
      _id: docId,
      rec: '',
    });
    // eslint-disable-next-line
    const {removeCurrentTab} = useTabs({onReload});
    const currentUser = useSelector(state => state.currentUser);
    const disabled = !checkUserRoles(currentUser, ['local-admin', 'gerencia', 'obra-edit'])
    const title = !doc || docId === 'new' ? "Nueva Obra" : (edit ? "Edición de Obra "  : "Obra " );

    useEffect( () => {
      debugger
      if ( docId === 'new' ) return 
      setReload(false);
      setLoading(true);
      // reads data to Edit instead of using datagrid row
      getAPI('/project/' + docId)
      .then(data => {
        if (data) setDoc(data)
        setLoading(false);
      })
      .catch(err => {
          debugger
          console.log(err.message)
          enqueueSnackbar(err.message, {variant: "error"})
          setLoading(false);
        });   
    }, [docId, edit, reload, enqueueSnackbar])

    const handleEdit = () => {
      setEdit(!edit)
    }
    // const handleInspect = () => {
    //   setInspect(true)
    //   setEdit(false)
    // }
    const handleCancel = () => {
      setEdit(false)
      if (docId === 'new') {
        removeCurrentTab()
      }
      if (onReload) onReload()
    }

    const handleClose = (ev) => {
      setEdit(false)
      if (onReload) onReload()
    }

    const handleReload = () => {
      setReload(true)
      if (onReload) onReload()
    }

    // eslint-disable-next-line
    const handleChangeStatus = newStatus => ev => {
      ev.preventDefault()
      debugger
      postAPI('/project/' + docId + '/changeStatus', {status: newStatus})
      .then(data => {
          debugger
          enqueueSnackbar("Se guardó el cambio correctamente", {variant: 'info'})
          setReload(true)
          if (onReload) onReload()

          const msg = `Se cambió de estado de "${doc.status}" a "${newStatus}"`
          activityLog({label: doc.nomobre, app: 'obras', docId: doc._id, msg: msg, currentUser})
      })
      .catch(err => {
          enqueueSnackbar(err.message, {variant: 'error'})
      });
          
    }
    
    const info = [
      <Box sx={{float: 'right'}}>
          { doc.status === 'T'    && <Button color='yellow' onClick={ handleChangeStatus("CO")} size='small' variant='contained'>COBRADA</Button>} &nbsp;
          { doc.status === 'F'    && <Button color='green'  onClick={ handleChangeStatus("C")} size='small' variant='contained'>COLOCACION</Button>} &nbsp;
          { doc.status === 'T'    && <Button color='red'    onClick={ handleChangeStatus("INCO")} size='small' variant='contained'>INCOBRABLE</Button>} &nbsp;
          { doc.status === 'C'    && <Button color='green'  onClick={ handleChangeStatus("C90")} size='small' variant='contained'>C90</Button>} &nbsp;
          { doc.status === 'C'    && <Button color='red'    onClick={ handleChangeStatus("F")} size='small' variant='contained'>FABRICACION</Button>} &nbsp;
          { doc.status === 'C90'  && <Button color='green'  onClick={ handleChangeStatus("T")} size='small' variant='contained'>TERMINADA</Button>} &nbsp;
          { doc.status === 'C90'  && <Button color='red'    onClick={ handleChangeStatus("TCP")} size='small' variant='contained'>TCP</Button>} &nbsp;
          { doc.status === 'TCP'  && <Button color='green'  onClick={ handleChangeStatus("T")} size='small' variant='contained'>TERMINADA</Button>} &nbsp;
          { doc.status === 'TCP'  && <Button color='red'    onClick={ handleChangeStatus("INCO")} size='small' variant='contained'>INCOBRABLE</Button>} &nbsp;
          { doc.status === 'INCO' && <Button color='green'  onClick={ handleChangeStatus("T")} size='small' variant='contained'>TERMINADA</Button>} &nbsp;
      </Box>
    ]

    return (
        <Paper 
          sx={{
            width: '100%',
            height: '100%',
          }}
          {...other}
        > 
          <HeaderBar 
            // sx={{'@media print': {display: 'none'}}}

            title={[
              // <Box sx={{display: 'inline-block'}}>Partido/Provincia: [{doc.partido} / {doc.provincia}] </Box>,

              <Box key='1' sx={{mt: 2}}>
                
                {title} 

                <Box sx={{float:'right', display: 'inline'}}>
                    <ActivityOpenClose sx={{float: 'right', color: 'inherit'}} app='obras' docId={docId}/>

                    {!edit && docId !== 'new' && <PrintPreview sx={{float: 'right', color: '#ddd'}} component={<ProjectPrint doc={doc}/>}/>}

                    <Button autoFocus color="inherit" sx={{float: "right"}} disabled={disabled} onClick={handleEdit} size='small' variant="outlined">{!edit && docId !== 'new'  ? "Editar" : "Cancelar"}</Button>
                  
                    { !edit && !disabled && info }    
                </Box>

                { docId !== 'new' && 
                  <Typography sx={{ color: 'white', display: 'inline'}} variant='h6'>
                    [{doc.carpeta}] {doc.nombre} ({doc.status}) &nbsp;

                    {doc.status === 'FABRICACION' && " el " + moment(doc.fecha_fabricacion).utc().format('YYYY-MM-DD') }
                    {doc.status === 'COLOCACION' && " el " + moment(doc.fecha_colocacion).utc().format('YYYY-MM-DD') }
                    {doc.status === 'TERMINADA' && " el " + moment(doc.fecha_terminacion).utc().format('YYYY-MM-DD') }
                </Typography>   
                } 
              </Box>
            ]}
          />

          { loading && <LinearProgress/> }

          { !edit && docId !== 'new' && 
            <ProjectShow 
              className="MuiProjectShowEdit-detail"
              defaultDoc={doc}
              onReload={handleReload}
            /> 
          }

          { (edit || docId === 'new') && 
            <ProjectEdit 
                className="MuiProjectShowEdit-edit"
                edit={edit || docId === 'new'}
                docId={docId} 
                onAfterSubmit={handleClose}
                onCancel={handleCancel}
            /> 
          }

        </Paper>
  )
}

const ProjectShowEditStyled = styled(ProjectShowEdit)(
  ({ theme }) => ({
    "& .MuiProjectShowEdit-detail": {
      overflow: 'auto',
      height: '100%',
    },
    "& .MuiProjectShowEdit-edit": {
      overflow: 'auto',
      height: 'calc(100% - 190px)',
      margin: '5px',
      padding: '15px'
    },
    "& .MuiButton-root": {
      marginLeft: "4px",
      [theme.breakpoints.down('md')]: {
          lineHeight: 1,
          fontSize: "11px",
          padding: '4px 4px',
          fontWeight: 800,
      },
    }
  })
)

export default ProjectShowEditStyled;
