import React from 'react'
import { AutocompleteBase, AutocompleteInput, AutocompleteObjectInput } from '../../../components'

const UserAutocomplete = ({vendedor=false, posventa=false, helperText, onInputChange, ...props}) => {
    const getOptionLabel = (it) => {
        //console.log("[userAutocomplete] getOptionLabel", it)
        return it && it.username ? it.username : ""
    }
    const isOptionEqualToValue = (option, value) => {
        return option && value && option.username === value.username
    }
    const qParams = {}
    if (vendedor) {
        qParams['vendedor'] = 1;
    }
    if (posventa) {
        qParams['posventa'] = 1;
    }
    return (
        <AutocompleteBase
            sx={{width: '100%'}}
            label="Users"
            //filterSelectedOptions
            autocomplete_method='/settings/users/autocomplete'
            autocomplete_params={qParams}
            getOptionLabel={getOptionLabel}
            renderOption={(props, option) => <li {...props}>{option.username + " (" + option.name + " "  + option.surname + ")"}</li> }
            isOptionEqualToValue={isOptionEqualToValue}
            disableClearable={false}
            errorText={helperText}
            onInputChange={onInputChange}
            {...props}
        />
    )
}

export default UserAutocomplete;
    
export const UserAutocompleteInput = ({...other}) => {
    return (
        <AutocompleteInput 
            field='username'
            {...other} 
            Component={UserAutocomplete}
        />
    )
}

export const UserAutocompleteObjectInput = ({...other1}) => {
    const UUAutocomplete = ({...other}) => UserAutocomplete({...other, ...other1})
    return (
        <AutocompleteObjectInput 
            {...other1} 
            Component={UUAutocomplete}
        />
    )
}

export const VendedorAutocompleteObjectInput = ({...other}) => {
    const VendedorAutocomplete = ({...other}) => UserAutocomplete({...other, vendedor:true})
    return (
        <AutocompleteObjectInput 
            {...other} 
            Component={VendedorAutocomplete}
        />
    )
}

export const PosventaAutocompleteObjectInput = ({...other}) => {
    const PosventaAutocomplete = ({...other}) => UserAutocomplete({...other, posventa:true})
    return (
        <AutocompleteObjectInput 
            {...other} 
            Component={PosventaAutocomplete}
        />
    )
}