import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinkIcon from '@mui/icons-material/Link';

import FileList from '../Attachments/FileList';

import {Attributes} from '../../components';

import moment from 'moment'


const AttributesStyled = styled(Attributes)(({ theme }) => ({
    '.MuiAttributes-title': {
        borderBottom: '1px solid'
    },
    "@media print": {
        "@page": {
            "size": "portrait",
            "margin": "10mm 5mm 5mm 5mm",
            "mso-header-margin":"0mm",
            "mso-footer-margin":"0mm",
            "mso-paper-source":0,
        },    
        '.MuiBackdrop-root': {
            display: 'none'
        },
        '.MuiAttributes-label': {
            fontSize: '9px'
        },
        '.MuiAttributes-text': {
            fontSize: '10px'
        },
        '.MuiAttributes-title': {
            fontSize: '11px'
        },
        'ul, ul li, table td, table th': {
            fontSize: "10px"
        },
    }
}));

const OpportunityPrint = ({doc}) => {

    const contacto = doc.contacto ? doc.contacto : {}; 

    const getName = () => {
        if (!contacto) return "";
        return contacto.fullname;
    }
    const getDireccion = () => {
        let out = ""
        if (doc.barrio) {
            out = "BARRIO " + doc.barrio + " #" + doc.lote + ", "
        }
        out += doc.direccion 
        out += " " + doc.localidad
        out += " " + doc.partido
        return out
    }
    const getTelefono = () => {
        if (contacto.celular) {
            return contacto.celular;
        } else return contacto.telefono
    }

    return (
        <>
            <Typography sx={{mb:1}} variant='h5'>Oportunidad {doc.nombre}</Typography>

            <AttributesStyled
                sizes={{xs: 3}}
                list={
                    [
                        {title: 'DESCRIPCION', sizes: {xs:2}},
                        {label: 'Descripción',  text: (doc.descripcion || 'SIN'), sizes: {xs:6}},
                        {label: 'Estado',  text: doc.status},

                        {title: 'CONTACTO', sizes: {xs:2}},
                        {label: 'Contacto',     text: getName()},
                        {label: 'Email',        children: <span style={{display: 'flex'}}><p className="contactEmail">{contacto.email}</p>{contacto.email && <a style={{float:'right'}} href={"mailto:"+contacto.email}><LinkIcon fontSize='small'/></a>}</span>},
                        {label: 'Telefono/Celular',     text: getTelefono()},
                        {sizes: {xs:2}},
                        {label: 'Direccion',    text: getDireccion(), sizes: {xs:6}},
                        {},
            
                        {title: 'PROPUESTA', sizes: {xs:2}},
                        {label: 'Vendedor',       text: doc.ownerId ? doc.ownerId.username : "",    sizes: {xs:2}},
                        {label: 'Presupuesto',    text: doc.presupuesto,    sizes: {xs:2}},
                        {label: 'Probabilidad',   text: doc.exito + "%",    sizes: {xs:2}},
                        {label: 'Distribuidor',          text: doc.distributorId && doc.distributorId.codigo, sizes: {xs:2}},
                        {label: 'Ingresado Por',  text: doc.ingresado_por, sizes: {xs:2}},
                        {sizes: {xs:2}},
                        {label: 'Valor',          text: doc.valor + " " + doc.moneda, sizes: {xs:2}},
                        {label: 'Valor Fab.',     text: doc.dto_profesional + " " + doc.moneda, sizes: {xs:2}},
                        {label: 'Dto Profesional', text: doc.dto_profesional, sizes: {xs:2}},
                        {label: 'Fecha Ingreso',  text: doc.fecha_ingreso  && moment(doc.fecha_ingreso).utc().format('YYYY-MM-DD'), sizes: {xs:2}},
                        {label: 'Fecha Revision', text: doc.fecha_revision && moment(doc.fecha_revision).utc().format('YYYY-MM-DD'), sizes: {xs:2}},
                        {sizes: {xs:2}},
                        {label: 'Fecha Estimada', text: doc.fecha_estimada && moment(doc.fecha_estimada).utc().format('YYYY-MM-DD'), sizes: {xs:2}},
                        {label: 'Fecha Ganada',   text: doc.fecha_ganada ? moment(doc.fecha_ganada).utc().format('YYYY-MM-DD') : "-", sizes: {xs:2}},
                        {label: 'Fecha Cierre',   text: doc.fecha_cierre ? moment(doc.fecha_cierre).utc().format('YYYY-MM-DD') : "-", sizes: {xs:2}},
                        {label: 'Motivo Perdida', text: doc.motivo_perdida, sizes: {xs:2}},
                        {sizes: {xs:2}},
                        {title: 'CARACTERISTICAS', sizes: {xs:2}},
                        {label: 'Tipo Obra',    text: doc.tipo_obra,    sizes: {xs:2}},
                        {label: 'Cantidad',     text: doc.cantidad,     sizes: {xs:2}},
                        {label: 'ML',           text: doc.ml,           sizes: {xs:2}},
                        {label: 'M2',           text: doc.m2,           sizes: {xs:2}},
                        {sizes: {xs:2}},
                        {sizes: {xs:2}},
                        {label: 'Vidrios',      text: doc.vidrios,      sizes: {xs:2}},
                        {label: 'Material',     text: doc.material,     sizes: {xs:2}},
                        {label: 'Marca',        text: doc.marca,        sizes: {xs:2}},
                        {label: 'Terminacion',  text: doc.terminacion,  sizes: {xs:2}},
                        {label: 'Color',        text: doc.color,        sizes: {xs:2}},
                        {sizes: {xs:2}},
                        {label: "Premarcos",    text: doc.premarcos,    sizes: {xs:2}},
                        {label: "Contramarcos", text: doc.contramarcos, sizes: {xs:2}},
                        {label: "Mosquiteros",  text: doc.mosquiteros,  sizes: {xs:2}},
                        {label: "Cortinas",     text: doc.cortinas,     sizes: {xs:2}},
                        {sizes: {xs:2}},
                        {sizes: {xs:2}},
                        {label: 'Observación',  text: doc.observacion || 'SIN', sizes: {xs: 8}},
                        {sizes: {xs:2}},
                        {title: 'PARA APROBAR', sizes:{xs:2}},        
                        {label: 'Para aprobar',
                            children: <FileList 
                                        folder="/comercial/pres"
                                        docs={doc.para_aprobar} 
                                    />,
                            sizes: {xs:10}
                        },
                        {title: 'ARCHIVOS', sizes:{xs:2}},        
                        {label: 'Adjunto',                 
                            children: <FileList 
                                        folder="/comercial/adjuntos"
                                        docs={doc.archivo_adjunto} 
                                      />, 
                            sizes: {xs: 5}
                        },
                        {label: 'Presupuesto',
                            children: <FileList 
                                        folder="/comercial/pres"
                                        docs={doc.archivo_presupuesto} 
                                    />,
                            sizes: {xs: 5}
                        },
                    ]
                }
            />
            <Typography sx={{float:'right'}} variant="caption">Actualizado el {moment(doc.updated_at).utc().format('YYYY-MM-DD')}</Typography>
        </>

    )
}

export default OpportunityPrint;