import React from 'react'; 
import { useSelector } from "react-redux";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import {SelectField} from '../../components'; 
import PartAutocomplete from '../Part/PartAutocomplete';
import UnitsSelect from '../Part/UnitsSelect';
import AlmacenField from './AlmacenField';

import moment from 'moment';

const StockMoveEditContainer = ({edit, doc, error, colors, handleSubmit, handleCancel, handleChange, handleChangeLink}) => {
    const terminacionColores = useSelector( state => state.terminacionColores || [] );

    const colores = [{label: "SIN", value:"-1"}].concat(terminacionColores.map(it => ({label: it, value: it})))
    const linkeado = doc.linkeado;

    const partName = doc && doc.articulo ? (`[${doc.articulo.codigo}] ${doc.articulo.descripcion}`) : "";

    return (
        <Box className='root'>
            <Paper id='content' sx={{m:1, p:2}}>
                <Grid sx={{width: '100%'}} container spacing={2}>
                    <Grid item xs={9}>
                        {edit ?
                            <PartAutocomplete 
                                disabled={!edit} 
                                value={doc.articulo}
                                defaultValue={doc.articulo} 
                                onChange={handleChange('articulo')}
                                error={!!error['articulo']}
                                helperText={error['articulo']}
                            /> 
                            :
                            <TextField sx={{width:'100%'}} disabled label="Articulo" size='small' value={partName}/>
                        }
                    </Grid>
                    <Grid item xs={3}>
                            <SelectField 
                                sx={{width: '100%'}}
                                disabled={!edit} 
                                size='small' 
                                label='Color' 
                                options={colores.filter( it => {return colors.length ? colors.indexOf(it.label) > -1 : true}) } 
                                value={doc.articulo_color || "-1"} 
                                onChange={handleChange('articulo_color')}
                                error={!!error['articulo_color']}
                                helperText={error['articulo_color']}
                            />
                    </Grid>

                    <Grid item xs={12} sm={1}>
                        {doc.tipo === 'TRAN' || doc.tipo === 'BAJA' ? 
                            <Box className='label' sx={{fontWeight: 600, fontSize: 12}}>Almacen Salida</Box> :
                            <Box className='label' sx={{fontWeight: 600, fontSize: 12}}>Almacen Ingreso</Box>
                        }
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {['TRAN','AJS','AJS-COL','AJS-COL2','STK-VEN','BAJA'].indexOf(doc.tipo) > -1 && 
                            <AlmacenField 
                                partId={doc._id === 'new' && doc.articulo ? doc.articulo._id : ""} 
                                partCol={doc._id === 'new' && doc.articulo_color}
                                value={doc._id === 'new' ? doc.almacen && ((doc.almacen._id + "|" + doc.ubi1 + "|" + doc.ubi2 + "|" + doc.ubi3) || "") : doc.almacen && doc.almacen._id } 
                                ubi1={doc.ubi1}
                                ubi2={doc.ubi2}
                                ubi3={doc.ubi3}
                                exist={doc.exist}
                                reserved={doc.reserved}
                                size='small' 
                                disabled={!edit} 
                                onChange={handleChange}
                                error={!!error['almacen']} 
                                helperText={error['almacen']}
                            />
                        }
                        {['ING','MFG-STK'].indexOf(doc.tipo) > -1 && 
                            <AlmacenField 
                                // partId={doc._id === 'new' && doc.articulo ? doc.articulo._id : ""} 
                                // partCol={doc._id === 'new' && doc.articulo ? doc.articulo_color : ""}
                                value={(doc.almacen && doc.almacen._id) || ""} 
                                ubi1={doc.ubi1}
                                ubi2={doc.ubi2}
                                ubi3={doc.ubi3}
                                // exist={doc.exist}
                                // reserved={doc.reserved}
                                size='small' 
                                disabled={!edit} 
                                onChange={handleChange}
                                error={!!error['almacen']}
                                helperText={error['almacen']}
                            />
                        }

                        {/* {doc.almacen && doc.almacen._id || ""} */}
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField 
                            disabled={!edit} 
                            size='small' 
                            label='Cantidad Ori' 
                            value={doc.cantidad_ori || ""} 
                            onChange={handleChange("cantidad_ori")} 
                            error={!!error['cantidad_ori']}
                            helperText={error['cantidad_ori']}
                        />
                        <UnitsSelect 
                            sx={{width: '180px'}} 
                            disabled={!edit} 
                            size='small' 
                            baseUnit={doc.udm} 
                            value={doc.udm_ori || ""} 
                            onChange={handleChange("udm_ori")}
                            error={!!error['udm_ori']}
                            helperText={error['udm_ori']}
                        />
                    </Grid>
                    <Grid item md={3}>
                    </Grid>
                </Grid>

                {doc.linkeado && doc.tipo === 'TRAN' && 
                    <Box sx={{width: '100%'}}>
                        <Grid container>
                            <Grid item xs={12} md={1}>
                                <Box  sx={{fontWeight: 600, fontSize: 12}} className="label">Almacen Entrada</Box>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <AlmacenField 
                                    partId={linkeado._id === 'new' && linkeado.articulo ? linkeado.articulo._id : ""} 
                                    partCol={linkeado._id === 'new' && linkeado.articulo ? linkeado.articulo_color : ""}
                                    value={(linkeado.almacen && linkeado.almacen._id) || ""} 
                                    ubi1={linkeado.ubi1}
                                    ubi2={linkeado.ubi2}
                                    ubi3={linkeado.ubi3}
                                    size='small' 
                                    disabled={!edit} 
                                    onChange={handleChangeLink}
                                    error={!!error['almacen.linkeado']}
                                    helperText={error['almacen.linkeado']}
                                />
                            </Grid>
                            <Grid item xs={5}></Grid>
                        </Grid>
                    </Box>
                }
                {doc.linkeado && doc.tipo === 'AJS-COL2' && 
                    <Box sx={{width: '100%'}}>
                        <Grid container>
                            <Grid item xs={12} md={1}>
                                <Box  sx={{fontWeight: 600, fontSize: 12}} className="label">Nuevo Color</Box>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <SelectField 
                                    sx={{width:'100%'}} 
                                    label="Color Nuevo" 
                                    // value={linkeado._id === 'new' && linkeado.articulo ? linkeado.articulo_color : ""}
                                    value={linkeado.articulo_color || "-1"}

                                    disabled={!edit} 
                                    size='small' 
                                    options={colores} 
                                    onChange={handleChangeLink("articulo_color")}
                                    // error={!!error['articulo_color2']}
                                    // helperText={error['articulo_color2']}
                                />
                            </Grid>
                            <Grid item xs={5}></Grid>
                        </Grid>
                    </Box>
                }

                {edit && 
                    <Box sx={{p:2, ml: '35%'}}>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button variant='outlined' onClick={handleSubmit}>Guardar</Button>
                    </Box>
                }

            </Paper>
            {!edit && 
                <Box sx={{fontSize: 12, float:'right'}}>
                    Actualizado por {doc.ownerId && doc.ownerId.username} el {moment(doc.created_at).format("YYYY-MM-DD HH:MM")}
                </Box>
            }
        </Box>        
    )
}

const StockMoveEditContainerStyled = styled(StockMoveEditContainer)(({ theme }) => ({
    '.label': {
        fontWeight: 700
    },
    '& .MuiPopover-root .MuiModal-root': {
        backgroundColor: 'red'
    },
    "@media print": {
        "@page": {
            "size": "portrait",
            "margin": "10mm 5mm 5mm 5mm",
            "mso-header-margin":"0mm",
            "mso-footer-margin":"0mm",
            "mso-paper-source":0,
        },
        '.MuiTypography-h6': {
            fontSize: 11,
            fontWeight: 600,
            borderBottom: '1px solid'
        },
        '.MuiTypography-caption': {
            fontSize: 9
        },
        '.MuiTableContainer-root': {
            boxShadow: 0
        },
        '.MuiTable-root th': {
            fontSize: '9px',
            fontWeight: 800
        },
        '.MuiTable-root td': {
            fontSize: '8px'
        },
        '.MuiChip-label': {
            fontSize: '8px'
        }
    }
})
)

export default StockMoveEditContainerStyled
