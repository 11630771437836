import React, {useState, useEffect, useMemo} from 'react';
import { useSelector } from "react-redux";
import { useSnackbar } from 'notistack';
import { useParams, Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { POrderDetail } from './POrderDetail';


import {Attributes, AdjuntoToggle} from '../../components';
import { defaultTheme } from '../../utils';
import moment from 'moment'

import { styled } from '@mui/material/styles';

const AttributesStyled = styled(Attributes)(({ theme }) => ({
    '.MuiAttributes-root': {
        padding: "17px 21px",
        marginBottom: '5px'
    },
    '.MuiAttributes-title': {
        borderBottom: '1px solid'
    },
    '.MuiAttributes-label': {
        fontSize: '9px'
    },
    '.MuiAttributes-text': {
        fontSize: '10px'
    },
    "@media print": {
        '.MuiAttributes-title': {
            fontSize: '9px'
        },
        'ul, ul li, table td, table th': {
            fontSize: "10px"
        },

    }
}));

const POrderDetailStyled = styled(POrderDetail)(({ theme }) => ({
    '.MuiTableHead-root .MuiTableCell-root': {
        // display: 'none',
        fontSize: '9px'
    },
    '.MuiTableRow-root .MuiTableCell-root': {
        lineHeight: '12px'
    },
    '.MuiTableBody-root .MuiTableCell-root': {
        padding: 2,
        paddingRight: 4
    },
    '.MuiTableCell-body': {
        fontSize: 9
    },
    '.MuiTableCell-root .MuiIconButton-root': {
        display: 'none'
    },
}))

const POrderPrint = ({doc}) => {

    let skus = 0;
    if (doc.detail) {
        doc.detail.forEach( it => {
            skus += it.pedido || 0;
        })
    }

    const provee = doc && doc.provee || {};

    const aprobadoPor = doc.approvedById ? `${doc.approvedById.username} (${moment(doc.fecha_aprobado).utc().format('YYYY-MM-DD')})` : '-';
    
    return (
        <Box>
            {/* <span style={{float: 'right', fontSize: 9}}>{moment().format('YYYY-MM-DD')}</span> */}
            <Typography sx={{mb:1, fontSize: 11, fontWeight: 600, borderBottom: '1px solid'}} variant='h6'>Nota de Pedido NP-{doc.np}</Typography>

            <AttributesStyled
                sizes={{xs: 3}}
                list={
                    [
                        // {title: 'DETALLE'},
                        {label: 'NP',               text: doc.np},
                        {label: 'Status',           text: doc.status},
                        {label: 'Proveedor',        text: "["+provee.codigo_proveedor + "] " + provee.fullname},
                        {label: 'Fecha Entrega',    text: moment(doc.fecha_entrega).utc().format('YYYY-MM-DD')},
                        {label: 'Obra',             text: doc.obra && `[${doc.obra.carpeta}] ${doc.obra.nombre}` },
                        {label: 'Posventa',         text: doc.pv && `[${doc.pv.pv}] ${doc.pv.nombre}` },
                        {label: 'OF Rel',           text: doc.of && doc.of.of },
                        {label: 'NP Provee',        text: doc.np_provee },

                        {label: 'Descripción',      text: doc.descripcion},
                        {},
                        {label: 'Entrega',          text: doc.lugar_entrega },
                        {label: 'Direccion',        text: doc.lugar_entrega_dire },
                        // {},
                        // {},
                        {label: 'Fecha Aprobado',   text: `${doc.fecha_aprobado ? moment(doc.fecha_aprobado).utc().format('YYYY-MM-DD') : 'SIN'} (${doc.approvedById ? doc.approvedById.username : '-'})`},
                        {label: 'Fecha Pedido',     text: doc.fecha_pedido ? moment(doc.fecha_pedido).utc().format('YYYY-MM-DD') : 'SIN'},
                        {label: 'Fecha Confirmado', text: doc.fecha_confirmado ? moment(doc.fecha_confirmado).utc().format('YYYY-MM-DD') : 'SIN'},
                        {label: 'Fecha Recibido',   text: doc.fecha_recibido ? moment(doc.fecha_recibido).utc().format('YYYY-MM-DD') : 'SIN'},

                        {label: 'Total Pedido', text: doc.presupuesto + " " + doc.moneda},
                        {label: 'Tipo Cambio', text: <span>(USD={doc.tipo_cambio && doc.tipo_cambio['USDARG']} EUR={doc.tipo_cambio && doc.tipo_cambio['EURARG']})</span>},
                        doc.tipo !== 'VARIOS' ? {label: 'KG', text: doc.detail && doc.kg, sizes:{xs:2}} : {},
                        doc.tipo === 'VIDRIOS' ? {label: 'ML', text: doc.detail && doc.ml, sizes:{xs:2}} : {},
                        doc.tipo === 'VIDRIOS' ? {label: 'M2', text: doc.detail && doc.m2, sizes:{xs:2}} : {},
                    ]
                }
            />
            <Typography sx={{float:'right', fontSize: 9, mb:1}} variant="caption">
                Creado por {doc.ownerId && doc.ownerId.username} el {moment(doc.created_at).utc().format('YYYY-MM-DD')}. Modificado el {moment(doc.updated_at).utc().format('YYYY-MM-DD')}
            </Typography>
            
            <POrderDetailStyled 
                doc={doc}
                withActions={false}
                withRecs={false}
            />

            <AttributesStyled
                style={{border: 0, boxShadow: "none", padding: "5px"}}
                list={[
                    {label: "", sizes: {xs: 3}},
                    {label: 'Lineas', text: doc.detail && doc.detail.length},
                    {label: 'Skus', text: skus || 0},
                    doc.tipo !== 'VARIOS' ? {label: 'KG', text: doc.detail && doc.kg} : {},
                    doc.tipo === 'VIDRIOS' ? {label: 'ML', text: doc.detail && doc.ml} : {},
                    doc.tipo === 'VIDRIOS' ? {label: 'M2', text: doc.detail && doc.m2} : {},
                    {label: "", sizes: {xs: 4}},
                    {label: "Descuento", text: doc.tasa_desuento || 0, sizes: {xs: 1}},
                    {label: "Tipos de cambio", text: `USD=${doc.tipo_cambio && doc.tipo_cambio['USDARG']} EUR=${doc.tipo_cambio && doc.tipo_cambio['EURARG']}`},
                    {label: "", sizes: {xs: 1}},
                    {label: "TOTAL", text: `${Number(doc.presupuesto).toLocaleString('en-US')} ${doc.moneda}`}
                ]}
            />
        </Box>

    )
}

const POrderPrintStyled = styled(POrderPrint)(({ theme }) => ({
    '& .MuiPopover-root .MuiModal-root': {
        backgroundColor: 'red'
    },
    '.MuiTypography-h6': {
        fontSize: 9,
        fontWeight: 600,
        borderBottom: '1px solid'
    },
    "@media print": {
        "@page": {
            "size": "portrait",
            "margin": "10mm 5mm 5mm 5mm",
            "mso-header-margin":"0mm",
            "mso-footer-margin":"0mm",
            "mso-paper-source":0,
        },
        '.MuiTypography-caption': {
            fontSize: 9
        },
        '.MuiTableContainer-root': {
            boxShadow: 0
        },
        '.MuiTable-root th': {
            fontSize: '9px',
            fontWeight: 800
        },
        '.MuiTable-root td': {
            fontSize: '8px'
        },
        '.MuiChip-label': {
            fontSize: '8px'
        }
    }
})
)


export default POrderPrintStyled;