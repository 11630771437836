import React, {forwardRef, useState, useEffect, useReducer, useCallback, createContext} from 'react'; 
import { useSelector } from "react-redux";
import { useSnackbar } from 'notistack';

import { useSearchParams } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import Hidden from '@mui/material/Hidden';
import Box from '@mui/material/Box';
import {PageBase, PageTitle, SearchBox, ExportButton} from '../../components'; 
import TaskList from './TaskList';
import TaskTopicBox from './TaskTopicBox';
import TaskEditModal from './TaskEditModal';
import {UserSearchBox} from '../Settings/Users/UserSearchBox';
import ActivityOpenClose  from '../Activities/ActivityOpenClose';
import {useTabs, getAPI, deleteAPI, activityLog} from '../../utils' ;
import {options_task_status, EXCEL_LIMIT} from '../../appConfig';

import moment from 'moment'

export const ParamsContext = createContext(null);

function paramsReducer (state, action) {
    switch (action.type) {
        case 'setReload':
            state.reload = action.reload;
            return {...state, reload: action.reload}
            
        case 'setNum':
            state.num = action.num;
            return {...state, num: action.num};

        default:
            return state;
            //throw new Error();
    }
}


const IndexPage = forwardRef( ({ board={}, isTabSelected }, ref) => {
    const { enqueueSnackbar } = useSnackbar();
    const currentUser = useSelector(state => state.currentUser);
    let [searchParams] = useSearchParams();
    
    const [query, setQuery]   = useState(searchParams.get("query")  || {board: {}, topic: "-1", users:"-1", status: 'PENDIENTE', distribuidor:"-1"});
     const [editId, setEditId] = useState(undefined)
    const [reload, setReload]   = useState(false);
    const [params, dispatchParams] = useReducer(paramsReducer, {list:[], num:0, reload:false});

    const {appendTab} = useTabs({
        onReload: (reload) => {
            debugger
            setReload(reload)
        }
    });

    const limit = 100;
    
    const canOpts = useSelector( state => state.distributors)
    const optionsDistrib = canOpts ? ([{label: 'TODOS', value:'-1'}].concat(canOpts.map(it => ({label:it.nombre, value:it._id}) )  )) : [];

    useEffect( () => {
        debugger
        const aa = canOpts.filter( it => !!it.default );
        if (aa.length) {
            setQuery(st => ({...st, 'distribuidor':aa[0]._id}))
        } else {
            setQuery(st => ({...st, 'distribuidor':canOpts[0]._id}))
        }
    }, [canOpts])

    const options_status = [{label: "TODOS", value: "-1"}].
       concat(options_task_status.map(it => {return {label: it, value: it}}));

    useEffect( () => {
        debugger
        if (isTabSelected === true) {
            // setReload(true)
            dispatchParams({type: 'setReload', reload: true})
        }
    }, [isTabSelected]);

    useEffect( () => {
        if (board && Object.keys(board).length) {
            debugger
            setQuery(st => ({...st, board: board._id}))
        }
    }, [board])

    const handleChange = (fld) => ev => {
        const valor = ev.target.value;
        setQuery( o => ({...o, [fld]: valor}) )
    }
    const handleClick= tipo => item => (ev) => {
        debugger
        ev.preventDefault();
        if (item.id) appendTab( tipo )(item)
    }
    const handleEditId = (doc) => ev => {
        setEditId(doc._id)
    }
    const handleClose = (doc) => {
        setEditId(undefined)
        setReload(true)
    }
    const handleDelete = (doc) => (ev) => {
        debugger
        deleteAPI('/task/' + doc._id).then(res => {
            const msg = "Se borró la tarea \"" + doc.asunto + "\"";
            let label, app, refId
            if (doc.obra) {
                label = doc.obra.nombre
                app = 'obras'
                refId = doc.obra._id
            }
            if (doc.pv) {
                label = doc.pv.nombre
                app = 'posventa'
                refId = doc.pv._id
            }
            activityLog({label, app, docId:refId, msg, currentUser})
            enqueueSnackbar(msg, {variant: 'info'})
            setReload(true)
        })
    }

    const configReport = useCallback( async () => {
        const reportConfig = {
            title: `Tareas`,
            subTitle: "Creado por " + currentUser.username + " el " + moment().local().toISOString(),
            cols: [
                    {title: 'Tablero', field: '_', transform: (v, doc) => {
                        if (doc.tasklist.boardlist) {
                            return doc.tasklist.boardlist.name;
                        }
                    }},
                    {title: 'Categoria', field: '_', transform: (v, doc) => doc.tasklist && doc.tasklist.name},
                    {title: 'Asunto', field: 'asunto'},
                    {title: 'Status', field: 'status'},
                    {title: 'Obra', field: '_', transform: (v, doc) => doc.obra && `[${doc.obra.carpeta}] ${doc.obra.nombre}`  },
                    {title: 'Posventa', field: '_', transform: (v, doc) => doc.pv && `[${doc.pv.pv}] ${doc.pv.nombre}`  },
                    {title: 'OF/OS', field: '_', transform: (v, doc) => doc.of && `${doc.of.tipo === 'fabrica' ? 'OF':'OS'}-${doc.of.of}`},
                    {title: 'NP', field: '_', transform: (v, doc) => doc.np && `${doc.np.np}`},
                    {title: 'Distribuidor', field: '_', transform: (v, doc) => doc.distributorId && doc.distributorId.codigo},
                    {title: 'Responsable', field: '_', transform: (v, doc) => doc.ownerId && doc.ownerId.username},
                    {title: 'Actualizado', field: 'updated_at', transform: (v, doc) => moment(v).utc().format('YYYY-MM-DD')},
            ],
            styles: {}
        }
    
        const rows = await getAPI('/task', {...query, skip: 0, limit: 3*EXCEL_LIMIT})

        reportConfig['rows'] = rows.list;
    
        return reportConfig;
      }, [query]);

    return (
        <ParamsContext.Provider value={{params, dispatchParams}}>            
            <PageBase
                paperProps={{width:'100%', height: 'calc(100% - 98px)'}} 

                title={
                    <>
                        <PageTitle>Tareas ({params.num})</PageTitle>
                        <TaskTopicBox label="Topico" boardId={board._id} value={query['topic']} onChange={handleChange('topic')}/>
                        <SearchBox label="Filtro" value={query['filter']} onChange={handleChange('filter')}/>
                        <SearchBox label="Status" select options={options_status} value={query['status']} onChange={handleChange('status')}/>
                        <SearchBox sx={{minWidth:100, mr:1}} label="Distribuidor" select options={optionsDistrib} value={query['distribuidor']} onChange={handleChange('distribuidor')}/>
                        <Hidden mdDown>
                            <SearchBox label="Obra" value={query['obras']} onChange={handleChange('obras')}/>
                            <SearchBox label="PV" value={query['pv']} onChange={handleChange("pv")}/>
                            <UserSearchBox label="Responsable" defaultValue={query['users']} onChange={handleChange('users')}/>              
                        </Hidden>
                    </>
                }
                actions={
                    <Box>
                        {/* <Button sx={{m:1, display: 'inline'}} size='small' variant='outlined' onClick={handleClick(tipo)({id: true, _id: 'new'})}>Nuevo</Button>                         */}
                        <ExportButton configReport={configReport}>EXCEL</ExportButton>
                        <ActivityOpenClose app={'fabrica'} withLabel/>
                    </Box>
                }   
        >           
                <TaskList
                    query={query}
                    limit={limit}

                    onClick={handleClick}
                    onEdit={handleEditId}
                    onDelete={handleDelete}
                />    

                { editId && <TaskEditModal docId={editId} onClose={handleClose}/>}    


            </PageBase>
        </ParamsContext.Provider>
    )
})

export default IndexPage;