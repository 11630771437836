/* eslint-disable no-use-before-define */
import React from 'react';
import { makeStyles } from '@mui/styles';

import Autocomplete from '@mui/material/Autocomplete';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import {getAPI, debounce} from '../utils'; 
import _ from 'underscore';

const useStyles = makeStyles((theme) => ({
  root: {
    //width: 200,
    '& > * + *': {
      //marginTop: theme.spacing(2),
      fontSize: 12
    },
  },
  numDocs: {
    color: "#666",
    fontSize: 14
  }
}));

const AutocompleteBase = (props) => {
  const {
    //sx={},
    label='',
    autocomplete_method='retail_autocomplete',
    autocomplete_params="",
    customFetch=false,
    onInputChange=()=>{},
    onChangeSelection=()=>{},
    onCancel,
    onSubmit,
    onResults,
    getOptionLabel=(option) => option.name,
    defaultValue,
    value: valueProp,
    filterSelectedOptions=false,
    openOnFocus=true,
    withEscape=false,
    withNumDocuments=false,
    docText="items",
    variant="outlined",
    size="small",
    limitTags=3,
    clearAfterSubmit=true,
    disabled=false,
    multiple=false,
    className="",
    errorText="",
    debug=false,
    ...other
  } = props;

  const classes = useStyles();
  const [value, setValue] = React.useState( multiple ? [] : null);
  const [inputValue, setInputValue] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  let active = React.useRef(false);
  const loading = open && options.length === 0;

  // por si cambia el defaultValue hay que mandarlo de nuevo
  React.useEffect( () => {
    //debugger
    console.log('[autocompleteBase] defaultValue', label, defaultValue)
    if (multiple && !defaultValue) {
      setValue([])
    } else {
      setValue(defaultValue)
    }
  }, [ defaultValue, multiple, label ] );

  React.useEffect( () => {
    // debugger
    console.log('[autocompleteBase] recarga valueProp', label, valueProp)
    setValue(valueProp)
  }, [ valueProp ] );

  // cuando cambia TextField fuerza el fetch y recarga opciones
  const handleChangeInput = (event) => {
    setInputValue(event.target.value);
  };
  const handleKeyDown = (ev) => {
    const key = ev.key;
    //console.log('key', key)
    ev.stopPropagation()
    if (withEscape && key === 'Escape') {
        setOptions([]);
        if (onCancel) {
          onCancel()
        }
        const obj = _.isObject(defaultValue) ? _.extend({}, defaultValue) : defaultValue;
        setValue(obj)
        return
    }
    if (key === 'Enter' || key === 'Tab') {
      if (props.freeSolo) {
        debugger
        handleChange(ev, inputValue, 'new-option', 'freeSolo');
        setOpen(false)
        return
      } else {
        handleChange(ev, value, 'submit', '');
        return
      }
    }
  }

  const handleChange = (event, newValue, reason, details) => {
    onChangeSelection(event, newValue, reason, details);
    if (clearAfterSubmit && reason === 'submit') {
      console.log(inputValue)
      //setValue(multiple ? [] : "")
      setInputValue( "" )
      if (multiple) {
        setValue( [] )
      }
    } else {
      debugger
      setValue(newValue)
    }
  }
  const handleInputChange = (event, newInputValue, reason) => {
    console.log("[AutocompleteBase] handleInputChange", newInputValue, reason)
    setInputValue(newInputValue);
    onInputChange(event, newInputValue, reason)
  }
  
  const fetch =  React.useCallback(
                    debounce( 
                      (inputValue, callback) => {
                          console.log('[autocompleteBase] fetching', inputValue);
                          !!customFetch ? 
                              customFetch(inputValue).then(callback).catch(err => {console.error(err)})
                            :                
                            getAPI(autocomplete_method, {q:inputValue, ...autocomplete_params})
                              .then(callback)
                              .catch(err => {console.error(err)}
                            );
                        },
                    300),
                    [autocomplete_method, autocomplete_params]
                  )

  React.useEffect(() => {

      if (!open) return undefined;

      if (active.current) return undefined;
      active.current = true;

      /*
      if (openOnFocus && inputValue === '') {
        setOptions([]);
        active = false;
        return undefined;
      }
      */

      fetch(inputValue, (results) => {
        active.current = false;
        setOptions(results || []);
        if (onResults) {
          onResults(results || []);
        }
        if (results.length === 0) {
          setOpen(false)
        }
      });

      return () => {
        //active.current = false;
      };
    }, [inputValue, onResults, open, fetch]
  );
  //}, [inputValue, fetch, open, openOnFocus, onResults]); // genera loop de render
  //}, [inputValue, fetch, open, openOnFocus]);

  /*
  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  */

  return (
      <>
        { debug && (
          <Alert severity="info" style={{ marginBottom: 8 }}>
            <Box>inputvalue = {inputValue}</Box>
            <Box>value = {JSON.stringify(value)}</Box>
            <Box>defaultValue={JSON.stringify(defaultValue)}</Box>
            <Box>loading = {loading ? 1 : 0}</Box>
            <Box>open = {open ? 1 : 0} clearAfterSubmit = {clearAfterSubmit}</Box>
            <Box>options = {options.length}</Box>
          </Alert>
        )}
        <Autocomplete
          //id="tags-outlined"
          sx={{
            '& input': {
                width: 200
            },
          }}
          openOnFocus={openOnFocus}
          
          value={value}
          inputValue={inputValue}

          size={size}
          multiple={multiple}
          filterSelectedOptions={filterSelectedOptions}
          limitTags={limitTags}

          //defaultValue={defaultValue}
          open={open}
          disabled={disabled}
          onOpen={() => { setOpen(true); }}
          onClose={() => { setOpen(false); }}
          getOptionLabel={getOptionLabel}
          options={options}
          loading={loading}
          noOptionsText="Sin datos"
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant={variant}
              helperText={errorText ? errorText : ""}
              error={!!errorText}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    { !loading && withNumDocuments ? <span className={classes.numDocs}>{options.length} {docText}</span> : null }
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              //onChange={handleChangeInput}
              onKeyDown={handleKeyDown}
            />
          )}
          onChange={handleChange}
          onInputChange={handleInputChange}
          className={className}
          {...other}
        />
      </>
  );
}

export default AutocompleteBase;