import React, {useEffect} from 'react';
import useAutocomplete, {AutocompleteGetTagProps} from '@mui/material/useAutocomplete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { autocompleteClasses } from '@mui/material/Autocomplete';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import {getAPI, debounce} from '../../../utils'; 


import { AutocompleteObjectInput } from '../../../components'

const Root = styled('div')(
  ({ theme }) => `
  color: ${
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'
  };
  font-size: 14px;
`,
);

const Label = styled('label')`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
`;

const InputWrapper = styled('div')(
  ({ theme }) => `
  width: 300px;
  border: 1px solid ${theme.palette.mode === 'dark' ? '#434343' : '#d9d9d9'};
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
  }

  &.focused {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    box-shadow: 0 0 0 2px rgb(24 144 255 / 0.2);
  }

  & input {
    background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
    color: ${
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'
    };
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`,
);


function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon fontSize='small' onClick={onDelete} />
    </div>
  );
}

const StyledTag = styled(Tag)(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : '#fafafa'
  };
  border: 1px solid ${theme.palette.mode === 'dark' ? '#303030' : '#e8e8e8'};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    // font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`,
);

const Listbox = styled('ul')(
  ({ theme }) => `
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === 'dark' ? '#2b2b2b' : '#fafafa'};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`,
);

export default function AutocompleteHook({label, multiple=true, defaultValue=[], onChangeSelection}) {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [value, setValue] = React.useState(defaultValue); // Manage value manually

    console.log("AUTOCOMPLETE - defaultValue:", defaultValue);
    console.log("AUTOCOMPLETE - value:", value);

    // Sync value when defaultValue changes
    useEffect(() => {
      if (defaultValue.length > 0) {
          setValue(defaultValue);
        }
    }, [defaultValue]);
    
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setOptions([]);
    };

    const fetch = async (q='') => {
        setLoading(true);
        const res = await getAPI('/settings/users/autocomplete', {q})
        setLoading(false);
  
        setOptions([...res]);
    }

    const handleInputChange = (ev) => {        
        fetch(ev.target.value)
    }
    
    const {
        getRootProps,
        getInputLabelProps,
        getInputProps,
        getTagProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
        focused,
        setAnchorEl,
    } = useAutocomplete({
        id: 'customized-hook-demo',
        value, // Use state instead of defaultValue
        multiple,
        options,
        getOptionLabel: (option) => option.username,
        isOptionEqualToValue: (option, value) => option.username === value.username,
        onOpen: handleOpen,
        onClose: handleClose,
        onChange: (event, newValue, reason, details) => {
            setValue(newValue); // Update state
            onChangeSelection(event, newValue, reason, details); // Notify parent component
        },
        onInputChange: handleInputChange,
    });

    return (
        <Root>
        <div {...getRootProps()}>
            <Label {...getInputLabelProps()}>{label}</Label>
            <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
              {multiple && value.map((option, index) => {
                  const { key, ...tagProps } = getTagProps({ index });
                  return <StyledTag key={key} {...tagProps} label={option.username}/>;
              })}
              <input {...getInputProps()}/>
            </InputWrapper>
        </div>
        {groupedOptions.length > 0 ? (
            <Listbox {...getListboxProps()}>
            {groupedOptions.map((option, index) => {
                const { key, ...optionProps } = getOptionProps({ option, index });
                return (
                  <li key={key} {...optionProps}>
                      <span>{option.username}</span>
                      <CheckIcon fontSize="small" />
                  </li>
                );
            })}
            </Listbox>
        ) : null}
        </Root>
    );
}


export const UserMultiAutocompleteObjectInput = ({...other1}) => {
    // const UUAutocomplete = ({...other}) => AutocompleteHook({...other, ...other1})
    return (
        <AutocompleteObjectInput 
            {...other1} 
            Component={AutocompleteHook}
        />
    )
}
