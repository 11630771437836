import React, {createContext, useState, useRef, useMemo, useContext, useEffect, useCallback} from 'react';
import { useSelector, useDispatch } from "react-redux";

import useWebSocket, { ReadyState } from 'react-use-websocket';
import { getAPI } from '../../utils';
import moment from 'moment';

export const WebSocketContext = createContext(false, null, () => {})

export const WebSocketProvider = ({ children, ws_url }) => {
  const org = useSelector((state) => state.org);
  const currentUser = useSelector((state) => state.currentUser);
  const dispatch = useDispatch();
  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
                                                      ws_url || "ws:example",
                                                      {
                                                          share: false,
                                                          shouldReconnect: () => true,
                                                      },
                                                    )

  useEffect(()=>{
    // console.log('WebSocketProvider lastJsonMessage cambio')
  },[lastJsonMessage])
                                                
  useEffect(() => {
        console.log("Connection state changed")
        if (readyState === ReadyState.OPEN) {
          sendJsonMessage({
            type: "subscribe",
            data: {
              channel: "general-chatroom",
            },
          })
        }
  }, [readyState, sendJsonMessage])

  // Receive commands from server
  useEffect(() => {
      async function fetchData() {
        const {type, data} = lastJsonMessage || {};
        if (type === 'usersChannel') {
          if (data.logoutUser) {
            await getAPI('/logout/' + data.logoutUser )
          }
        }
      }
      fetchData()
      // console.log(`Got a new message: ${lastJsonMessage}`)
  }, [lastJsonMessage])

  const ret = [readyState, lastJsonMessage, sendJsonMessage]

  return (
    <WebSocketContext.Provider value={ret}>
      {children}
    </WebSocketContext.Provider>
  )
}
  
export const useChannel = (channelName="", onMessage=()=>{}) => {
    const [last, setLast] = useState(null)
    const [isReady, lastMessage, sendMessage] = useContext(WebSocketContext);
    useEffect( () => {
      if (lastMessage && lastMessage.type === channelName) {
        if (onMessage) {
          onMessage(lastMessage)

          setLast((prevLast) => {
            return JSON.stringify(prevLast) !== JSON.stringify(lastMessage.data) ? lastMessage.data : prevLast;
          });      
          // setLast(lastMessage.data)
        }
      }
    }, [lastMessage, channelName, onMessage])

    const sendToChannel = useCallback((msg) => {
      sendMessage({
        type: channelName,
        data: msg
      })
    }, [channelName, sendMessage])

    // useEffect(()=>{
    //   console.log('useChannel lastMessage cambio')
    // },[lastMessage])

    return [isReady, last, sendToChannel]
  }

  export const useUsersChannel = (onMessage=()=>{}) => {
    const noUserId = 'NO-USER';
    const [isReady, last, sendMessage] = useChannel('usersChannel', onMessage);
    
    useEffect(() => {
      console.log('[useUsersChannel] last cambio')
    }, [last])
    useEffect(() => {
      console.log('[useUsersChannel] sendMessage cambio')
    }, [sendMessage])
  
    // Memoize last.users to prevent unnecessary re-renders
    const lastUsers = useRef({});
    const ll = JSON.stringify(lastUsers.current);
    if (last?.users && ll !== JSON.stringify(last.users)) {
      lastUsers.current = last.users;
    }

    const onlineUsers = lastUsers.current;
    const onlineByUsers = {};
    Object.values(onlineUsers).map( cur => {
      if (cur.location) {
        onlineByUsers[cur.username] = onlineByUsers[cur.username] || {
          id: cur.username || noUserId, 
          username: cur.username, 
          initials: cur.initials, 
          location: cur.location, 
          sessions: 0, 
          activity: 0, 
          lastUpdate: null
        };
        onlineByUsers[cur.username]['sessions'] += 1;
        onlineByUsers[cur.username]['activity'] += cur.activity;
        if (cur.lastUpdate && !onlineByUsers[cur.username]['lastUpdate']) {
          onlineByUsers[cur.username]['lastUpdate'] = moment(cur.lastUpdate)
        }
        if (onlineByUsers[cur.username]['lastUpdate'] && moment(cur.lastUpdate).diff(moment(onlineByUsers[cur.username]['lastUpdate']), 'seconds') > 0) {
          onlineByUsers[cur.username]['lastUpdate'] = moment(cur.lastUpdate);
        }
      }
    })
    
    const onlineByLocation = {};
    Object.values(onlineUsers).map( cur => {
      if (cur.location) {
        onlineByLocation[cur.location] = onlineByLocation[cur.location] || {};
        onlineByLocation[cur.location][cur.username] = onlineByLocation[cur.location][cur.username] || {id: cur.username || noUserId, username: cur.username, initials: cur.initials, location: cur.location, sessions: 0, activity: 0}
        onlineByLocation[cur.location][cur.username]['sessions'] += 1;
        onlineByLocation[cur.location][cur.username]['activity'] += cur.activity;
      }
    })

    const reloadConfigs = useCallback( v => sendMessage({reloadConfigs: true}), [sendMessage]);
    const reloadUser = useCallback( id => sendMessage({reloadUser: id}), [sendMessage]);
    const logoutUser = useCallback( id => sendMessage({logoutUser: id}), [sendMessage]);
    const reportActivity = useCallback( (id, location, stat) => sendMessage({userId:id, location, activity: stat}), [sendMessage]);
    const isUserValid = useCallback( (id) => sendMessage({ping:true, userId:id}), [sendMessage]);
    const connectUser = useCallback( (org, token, currentUser) => {
      if (currentUser) {
        sendMessage({
          subscribe: true,
          org, 
          token,
          userId: currentUser._id, 
          username: currentUser.username, 
          initials: currentUser && currentUser.name && currentUser.surname &&(currentUser.name[0] + currentUser.surname[0]),
        });
      }
    }, [sendMessage]);

    return {
      isReady, 
      sendUserMessage: sendMessage, 

      reloadConfigs,
      reloadUser,
      logoutUser,
      onlineUsers,
      onlineByUsers,
      onlineByLocation,
      reportActivity,
      isUserValid,
      connectUser,
    };

  }

  export default WebSocketProvider
  